<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				
			</div>
			<div class="title">
				<button type="button" class="pull-right btn btn-primary" style="margin-left: 5px;" @click="exportCSV" >
					<i class="fa-solid fa-file-csv"></i>
				</button>

				<button type="button" class="pull-right btn btn-danger" style="margin-left: 5px;" @click="clearPreviousDues" >
					<i class="fa-solid fa-money"></i>
				</button>


				Payment Overview &nbsp; &nbsp; &nbsp; 
				<label style="color: #222">
					<input type="radio" v-model="loadMonth" value="last" @change="loadLazyData"> Last Month
				</label>
				&nbsp; &nbsp; 
				<label style="color: #222">
					<input type="radio" v-model="loadMonth" value="this" @change="loadLazyData"> This Month
				</label>
			</div>
		</div>
		<div>
			<div style="height: 30px;"></div>
			<div class="container">
				<div class="row">
					<div class="col-4"></div>
					<div class="col-4">
						<div class="card" style="padding: 10px;">
							<div>
								<table class="table table-bordered table-striped" style="margin-bottom: 0">
									<tbody>
										<tr>
											<td>X-Ray Total</td>
											<td>{{ data?.xrayTotal }}</td>
										</tr>
										<tr>
											<td>ECG Total</td>
											<td>{{ data?.ecgTotal }}</td>
										</tr>
										<tr>
											<td>EEG Total</td>
											<td>{{ data?.eegTotal }}</td>
										</tr>
										<tr>
											<td>Radiologist Total</td>
											<td>{{ data?.radiologistTotal }}</td>
										</tr>
										<tr>
											<td>Cardiologist Total</td>
											<td>{{ data?.cardiologistTotal }}</td>
										</tr>
										<tr>
											<td>Neurologist Total</td>
											<td>{{ data?.neurologistTotal }}</td>
										</tr>
										<tr>
											<td>Agent Total</td>
											<td>{{ data?.agentTotal }}</td>
										</tr>
										<tr>
											<td>Payment Gateway Total</td>
											<td>{{ data?.paymentGetwayTotal }}</td>
										</tr>
										<tr>
											<td>Total In</td>
											<td>{{ data?.totalIn }}</td>
										</tr>
										<tr>
											<td>Total Out</td>
											<td>{{ data?.totalOut }}</td>
										</tr>
										<tr>
											<td>Total Profit</td>
											<td>{{ data?.totalProfit }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div style="padding: 30px;">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th>Name</th>
							<th>Type</th>
							<th>Bank Details</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="r in payments" :key="r">
							<td>{{ r[0] }}</td>
							<td>{{ r[1] }}</td>
							<td>{{ r[2] }}</td>
							<td>{{ r[3] }}</td>
						</tr>
					</tbody>
				</table>
			</div>

		</div>
		
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			data: null,
			isSuper: false,

			loadMonth: 'last',

			payments: [],

		}
	},
	mounted() {
		this.loadLazyData();
	},
	methods: {
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		async loadLazyData() {
			let d = await this.post("admin/load-payment-overview", {
				loadMonth: this.loadMonth
			});
			console.log(d);
			this.data = d;

			this.payments = d.payments;
		},
		async clearPreviousDues(){
			if(!confirm("Are you sure want to clear all previous dues?")) return;

			let d = await this.post("admin/clear-all-previous-dues", {});

			if(d.success){
				this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Payment cleared successfully.', life: 3000 });
			}else{
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to clear payments', life: 3000 });
			}
		},

		exportCSV(e){
			// let data = [
			// 	['Name', 'Type', 'Bank Details', 'Amount'],
			// 	['John Doe', 30, 'Software Engineer', 9],
			// 	['Jane Doe', 25, 'Web Developer', 8],
			// ];
			let data = this.payments;

			const csvString = data.map(row => row.join(',')).join('\n');
			const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'payment.csv';
			document.body.appendChild(link);
			link.click();
		}
	},
}
</script>